import config from './config';
import { PublicClientApplication } from "@azure/msal-browser";
import{getSecurityRoles} from './apiService'
import{getUsergroups} from './apiService'

const msalConfig = {
  auth: {
      clientId: config.auth.APPID,
      isBrowserEnvironment: true
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const logout = () => {
  sessionStorage.removeItem("tokenExpiration");
  msalInstance.logoutRedirect();
}

export const login = () => { }
export const getAuthConfig = () =>{
  const authRequest = {
    clientId: config.auth.APPID,
    authority: config.auth.AUTHORITY,
    redirectUri: config.auth.REDIRECT
  }
  return authRequest;
}

export const getToken = () => {
  const account = msalInstance.getAllAccounts()[0];
  const idTokenRequest = {
      scopes: ["user.read"],
      account: account
  }
  return new Promise((resolve) => {
    // Use the same publicClientApplication instance provided to MsalProvider
    msalInstance.acquireTokenSilent(idTokenRequest).then(function(idTokenResponse) {
      // Acquire token silent success
      let idToken = idTokenResponse.idToken;
      return resolve(idToken);
    }).catch(function (error) {
      //Acquire token silent failure
      console.log(error);
    });
  })
}
export const getAccessToken = () => {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
      scopes: ["user.read"],
      account: account
  }
  return new Promise((resolve, reject) => {
    // Use the same publicClientApplication instance provided to MsalProvider
    msalInstance.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
      // Acquire token silent success
      const response = {
        accessToken: accessTokenResponse.accessToken,
        expiresOn: accessTokenResponse.expiresOn,
      }
      return resolve(response);
    }).catch(function (error) {
      // Acquire token silent failure
      console.log(error);
      return reject(error);
    });
  })
}
const parseSecurityPermissions = (ADGroupsCallback, secGroupCallback) =>{
  const userSecPerm = {
    articles: null,
    templates: null,
    notifications: null,
    glossary: null,
    tags: null,
    vehicleMenu: null,
    app: null,
  }

  let secGroupIds = secGroupCallback.map(function(i){
    return i.secGroupId;
  });

  ADGroupsCallback.forEach(groupName => {
    if(secGroupIds.includes(groupName)){
      const rollSecPerm = secGroupCallback.filter(x => x.secGroupId === groupName);
      if(rollSecPerm[0].articles){userSecPerm.articles = true;}
      if(rollSecPerm[0].templates){userSecPerm.templates = true;}
      if(rollSecPerm[0].notifications){userSecPerm.notifications = true;}
      if(rollSecPerm[0].glossary){userSecPerm.glossary = true;}
      if(rollSecPerm[0].tags){userSecPerm.tags = true;}
      if(rollSecPerm[0].vehicleMenu){userSecPerm.vehicleMenu = true;}
      if(rollSecPerm[0].app){userSecPerm.app = true;}
    }
    else{
      
    }
  });
  return userSecPerm;
}
const getGroupIds = (userGroups) => {
  let groupIds = []
  for (var i in userGroups){
    groupIds.push((userGroups[i].id))
  }
  return groupIds
}
const getAllUserGroups = async (url) =>{
  return new Promise(async(resolve) =>{
    let response = []
    let { usergroups, ...rest} = await getUsergroups(url)
    response = [...response,...getGroupIds(usergroups.value)]
    while (usergroups['@odata.nextLink'] != null){
      const partialResponse = await getUsergroups(usergroups['@odata.nextLink'],response)
      usergroups = partialResponse.usergroups
      response = [...response,...getGroupIds(usergroups.value)]
    }
    resolve ({ allUserGroups: response, ...rest })
  })
}
export const setAuthGroup = () => {
  return new Promise((resolve, reject) => {
    // getUsergroups()
    //Requests AD Groups from Rukky's API
    getAllUserGroups(config.auth.GraphUrl)
    .then(({ allUserGroups, ...rest }) =>{
      getSecurityRoles()
      .then((secGroupCallback) => {
        const secPerm = parseSecurityPermissions(allUserGroups, secGroupCallback);
        resolve({ securityPermissions: secPerm, ...rest });
      })
      .catch((error) => {
        console.log(`ERROR: failed to establish Security Roles. ${JSON.stringify(error.statusText)}`);
        console.error(error);
      });
    }).catch(reject);
  })
  
}

export const checkAuthGroup = (currentAuthGroup) =>{
  return new Promise((resolve, reject) => {
    setAuthGroup()
    .then(({ securityPermissions, ...rest }) => {
      resolve({ authGroup: securityPermissions, ...rest });
    }).catch(reject);
  });
}