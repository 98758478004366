/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import './theme.js';
import './App.css';
import { logout } from './auth';

// COMPONENTS
import AppMenu from './components/Menu/appMenu';
import AppToolbar from './components/Menu/appToolbar';
import { FeedbackSnackbarWrapper } from './components/FeedbackSnackbar';

// ROUTER - PAGE COMPONENTS
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import Countdown from 'react-countdown';
import CreateArticleComponent from './containers/CreateArticle';
import CreateNotificationComponent from './containers/NotificationCreate';
import DialogV2 from './components/DialogV2/index.js';
import EditNotificationComponent from './containers/NotificationEdit';
import DeleteArticleComponent from './containers/DeleteArticle';
import EditTagGroupsComponent from './containers/EditTagGroups';
import EditTagsComponent from './containers/EditTags';
import HomePageComponent from './containers/Homepage';
import ManageGlossaryComponent from './containers/ManageGlossary'
import NotificationActivity from './containers/NotificationActivity';
import PromoteArticleComponent from './containers/PromoteArticle';
import PromoteMenuIconComponent from './containers/PromoteMenuIcon';
import PromoteTemplateComponent from './containers/PromoteTemplate';
import PublishTemplateComponent from './containers/PublishTemplate';
import UpdateArticleComponent from './containers/UpdateArticle';
import UpdateMenuIconComponent from './containers/UpdateMenuIcon';

// AUTHENTICATION
import { getAuthConfig, checkAuthGroup } from './auth';
import { MsalAuthenticationTemplate as Authenticator } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';


function App() {
  const [securityGroup, setSecurityGroup] = useState(null);
  const [pageTitle, setPageTitle] = useState('Welcome Back, Commander');
  const authConfig = getAuthConfig();
  const [persistentMenu, setPersistentMenu] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(true);

  // Auth hooks
  const [expireTokenTimeout, setExpireTokenTimeout] = useState(null);
  const [tokenExpirationTime, setTokenExpirationTime] = useState(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [timeoutModalContent, setTimeoutModalContent] = useState({});

  const FIVE_MIN_IN_MS = 1000 * 60 * 5;
  const ONE_HOUR_IN_MS = 1000 * 60 * 60;

  const theme = createTheme({
    palette: {
      primary: { main: '#0F7AEC' },
      secondary: { main: '#57C2D8', contrastText: '#fff' },
      error: { main: '#EB0A1E' },
      success: { main: '#72B85F' },
      warning: { main: '#F8D76B' },
      info: { main: '#7D4285' },
    },
  });

  useEffect(() => {
    loadSecurityGroup();
    setTimeout(loadSecurityGroup, 500);

    if(!sessionStorage.getItem("tokenExpiration")) {
      sessionStorage.setItem("tokenExpiration", Date.now() + ONE_HOUR_IN_MS);
    }
    setTokenExpirationTime(Number(sessionStorage.getItem("tokenExpiration")));
  }, []);

  useEffect(() => {
    // Upon each render this will check if the token expires within five minutes and if so will display the countdown in the toolbar
    if(tokenExpirationTime) {
      const timeUntilExpiration = tokenExpirationTime - new Date().getTime();
      setShowCountdown(timeUntilExpiration <= FIVE_MIN_IN_MS);
    }
  });

  useEffect(() => {
    if(tokenExpirationTime){
      const timeUntilExpiration = tokenExpirationTime - new Date().getTime();
      
      // If we are not already in the window five minutes prior to token timeout, create task that will popup modal 5 minutes prior to timeout
      if(timeUntilExpiration >= FIVE_MIN_IN_MS) {
        setExpireTokenTimeout( setTimeout(() => 
        {
          setShowCountdown(true);
          setTimeoutModalContent(getModalContent(false));
          setShowTimeoutModal(true);
        }, timeUntilExpiration - FIVE_MIN_IN_MS));
      }

      // When the token does expire, popup modal that says so
      setExpireTokenTimeout( setTimeout(() => 
      {
        setTimeoutModalContent(getModalContent(true));
        setShowTimeoutModal(true);
      }, timeUntilExpiration));
    }
  },[tokenExpirationTime]);

  function loadSecurityGroup() {
    if (!securityGroup) {
      checkAuthGroup(securityGroup)
        .then(result => setSecurityGroup(result.authGroup));
    }
  }

  useEffect(() => {
    // This has the dual effect of setting the page title in the toolbar & triggering the tab being renamed
    switch (window.location.pathname) {
      case '/': setPageTitle('Welcome Back, Commander'); break;
      case '/articles/update': setPageTitle('Edit Article'); break;
      case '/articles/delete': setPageTitle('Delete Article'); break;
      case '/articles/create': setPageTitle('Create New Article'); break;
      case '/articles/promote': setPageTitle('Promote Article'); break;
      case '/templates/publish': setPageTitle('Upload New Template'); break;
      case '/templates/promote': setPageTitle('Promote Template'); break;
      case '/notifications/activity': setPageTitle('Notification Activity'); break;
      case '/notifications/create': setPageTitle('Create New Notification'); break;
      case '/notifications/edit': setPageTitle('Edit Notification'); break;
      case '/glossary': setPageTitle('Manage Glossary'); break;
      case '/tags/edit-tags': setPageTitle('Edit Tags'); break;
      case '/tags/edit-tag-groups': setPageTitle('Edit Tag Groups'); break;
      case '/editMenuItems': setPageTitle('Edit Menu Items'); break;
      case '/vehicle-menu/update': setPageTitle('Update Vehicle Menu Image'); break;
      case '/vehicle-menu/promote': setPageTitle('Promote Vehicle Menu Image'); break;
      case '/app/promote-content': setPageTitle('Promote App Content'); break;
      case '/app/promoted-menu-items': setPageTitle('Promoted App Menu Items'); break;
      case '/app/enabled-vehicles': setPageTitle('App Enabled Vehicles'); break;
      case '/app/create-build': setPageTitle('Create App Build'); break;
    }
  }, []);

  useEffect(() => {
    if (pageTitle.indexOf('Welcome') >= 0) {
      document.title = `Mission Control`;
    } else {
      document.title = `${pageTitle} | Mission Control`;
    }
  }, [pageTitle]);

  useEffect(() => {
    updateMenu();
    window.addEventListener("resize", updateMenu);
    return () => {
      window.removeEventListener("resize", updateMenu);

      if (expireTokenTimeout) {
        clearTimeout(expireTokenTimeout);
        setShowCountdown(false);
      }
    }
  }, []);

  function updateMenu() {
    if (window.innerWidth >= 1250) {
      setPersistentMenu(true);
      setDrawerOpen(true);
    } else {
      setPersistentMenu(false);
      setDrawerOpen(false);
    }
  }

function closeModal() {
    setShowTimeoutModal(false);
}

function showModal(isExpired) {
  setTimeoutModalContent(getModalContent(isExpired));
  setShowTimeoutModal(true);
}

function getModalContent(isExpired) {
  const countdownJSX = <Countdown date={tokenExpirationTime} renderer={renderer}/>;
  const warningContent = {
    title: "Session Expiring Soon", 
    message: <React.Fragment>Your session will expire in <b>{countdownJSX}</b>. Please log out and log in again, or open a new window, to refresh your session.</React.Fragment>, 
    severity:"warning"};

  const expiredContent = {
    title: "Session Expired", 
    message: "Your session is expired. Please log out and log in again, or open a new window, to refresh your session.", 
    severity: "error"};

  if(isExpired) {
    return expiredContent;
  } else {
    return warningContent;
  }
}

const renderer = ({ minutes, seconds}) => {
  // Countdown formatting, m:ss
  return <span>{minutes}:{seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}</span>;
};

  return (
    <Authenticator
      interactionType={InteractionType.Redirect}
      authenticationRequest={authConfig}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme} >
        <DialogV2
            open={showTimeoutModal}
            title={timeoutModalContent.title}
            content={<Alert severity={timeoutModalContent.severity}>{timeoutModalContent.message}</Alert>}
            actions={
              <div>
                <Button
                    onClick={closeModal}
                    style={{ marginRight:"10px" }}
                    color="primary"
                    variant="text"
                >Dismiss</Button>
                <Button
                    onClick={logout}
                    style={{ color: '#FFFFFF', backgroundColor: "#EB0A1E", marginLeft:"10px"}}
                    variant="contained"
                >Logout</Button>
              </div>
            }
        />
        
        <FeedbackSnackbarWrapper>
          <div className={`app ${persistentMenu ? 'persistent-menu' : ''}`}>
            <AppMenu
              securityGroup={securityGroup}
              persistentMenu={persistentMenu}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
            <AppToolbar
              pageTitle={pageTitle}
              persistentMenu={persistentMenu}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              showCountdown={showCountdown}
              expirationTime={tokenExpirationTime}
              countdownRenderer={renderer}
              showModalFunction={showModal}
            />

            {/* ROUTES */}
            <Route exact path="/" render={() => <HomePageComponent securityGroup={securityGroup} />} />

            {securityGroup && securityGroup?.articles && <>
              <Route path="/articles/create" render={() => <CreateArticleComponent />} />
              <Route path="/articles/update" render={() => <UpdateArticleComponent />} />
              <Route path="/articles/delete" render={() => <DeleteArticleComponent />} />
              <Route path="/articles/promote" render={() => <PromoteArticleComponent />} />
            </>}

            {securityGroup && securityGroup?.templates && <>
              <Route path="/templates/publish" render={() => <PublishTemplateComponent />} />
              <Route path="/templates/promote" render={() => <PromoteTemplateComponent />} />
            </>}

            {securityGroup && securityGroup?.notifications && <>
              <Route path="/notifications/activity" render={() => <NotificationActivity />} />
              <Route path="/notifications/create" render={() => <CreateNotificationComponent />} />
              <Route path="/notifications/edit" render={() => <EditNotificationComponent />} />
            </>}

            {securityGroup && securityGroup?.glossary && <>
              <Route path="/glossary" render={() => <ManageGlossaryComponent />} />
            </>}

            {securityGroup && securityGroup?.tags && <>
              <Route path="/tags/edit-tags" render={() => <EditTagsComponent />} />
              <Route path="/tags/edit-tag-groups" render={() => <EditTagGroupsComponent />} />
            </>}

            {securityGroup && securityGroup?.vehicleMenu && <>
              <Route path="/vehicle-menu/update" render={() => <UpdateMenuIconComponent />} />
              <Route path="/vehicle-menu/promote" render={() => <PromoteMenuIconComponent />} />
            </>}
          </div>
          </FeedbackSnackbarWrapper>
        </ThemeProvider>
      </BrowserRouter>
    </Authenticator>
  );
}

export default App;